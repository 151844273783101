import React from 'react'
import Container from '../components/container'
import Layout from '../components/layout';
import colors from '../utils/colors'
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { StaticQuery, graphql } from 'gatsby';



const styles = {
    primary: {
        WebkitTransition: `all 0.3s`,
        MozTransition: `all 0.3s`,
        transition: `all 0.3s`,
        background: colors.darkPrimaryColor,
        color: `#FFF`,
        marginTop: `1rem`,
        borderColor: `#FFF`,
        ":hover": {
            background: `#FFF`,
            color: colors.darkPrimaryColor
        }
    }
}
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.state = { firstName: '', lastName: '', email: '', contactMsg: '' }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        fetch("/thanks-contact/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
        })
            .then((response) => window.location.replace(response.url))
            .catch(error => alert(error))

        e.preventDefault();
    }

    render() {
        return (
            <StaticQuery query={
                graphql`
                    query ContactQuery {
                        datoCmsContactPage {
                        contactFormHeader
                    }
                }`
            }
                render={(data) => (
                    <Layout location={this.props.location}>
                        <Container css={{ display: `flex`, alignItems: `center`, flexDirection: `column`, flex: `1 0 80%`, marginLeft: `16rem`, marginTop: `3.5rem` }}>
                            <h1 style={{
                                borderBottom: `1px solid black`, textAlign: `center`, width: `50%`, color: `${colors.darkPrimaryColor}`, paddingBottom: `20px`
                            }}>{data.datoCmsContactPage.contactFormHeader}</h1>
                            <Form style={{ display: `flex`, alignSelf: `center`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }} name="contact"
                                method="post"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                                action="/thanks-contact">
                                <p hidden>
                                    <Label>
                                        Don’t fill this out: <input name="bot-field" />
                                    </Label>
                                </p>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input
                                        type={'text'}
                                        title={'First name'}
                                        name={'firstName'}
                                        placeholder={''}
                                        onChange={this.handleChange}
                                        invalid={this.state.firstName ? false : true}

                                    />
                                    <FormFeedback>Please enter your first name.</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input
                                        type={'text'}
                                        title={'Last name'}
                                        name={'lastName'}
                                        placeholder={''}
                                        onChange={this.handleChange}
                                        invalid={this.state.lastName ? false : true}
                                    />
                                    <FormFeedback>Please enter your last name.</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type={'email'}
                                        title={'Email'}
                                        name={'email'}
                                        placeholder={''}
                                        onChange={this.handleChange}
                                        invalid={this.state.email ? false : true}
                                    />
                                    <FormFeedback>Please enter a valid email address.</FormFeedback>

                                </FormGroup>
                                <FormGroup>
                                    <Label for="contactMsg">Message</Label>
                                    <Input
                                        title={""}
                                        type="textarea"
                                        rows={5}
                                        resize={'false'}
                                        val={this.state.contactMsg}
                                        name={'contactMsg'}
                                        onChange={this.handleChange}
                                        invalid={this.state.contactMsg ? false : true}
                                        placeholder={'How can we help?'} />
                                    <FormFeedback>Please enter a message.</FormFeedback>
                                </FormGroup>
                                <div>
                                    <p>
                                        <Button size="lg" color="primary" style={styles.primary} type="submit" disabled={!this.state.email || !this.state.firstName || !this.state.lastName || !this.state.contactMsg} >
                                            Submit
                                            </Button>
                                    </p>
                                </div>
                            </Form>
                        </Container>
                    </ Layout>
                )}
            />
        )
    }
}

export default Contact
